// extracted by mini-css-extract-plugin
export var coachingBorderImage = "coaching-module--coaching-border-image---TxGH";
export var coachingContainer = "coaching-module--coaching-container--dlSls";
export var coachingCourseCard = "coaching-module--coaching-course-card--+Znor";
export var coachingCourseTag = "coaching-module--coaching-course-tag--9EMyN";
export var coachingCourseTagIcon = "coaching-module--coaching-course-tag-icon--BVk07";
export var coachingCourseTagText = "coaching-module--coaching-course-tag-text--teKE3";
export var coachingCourseTagsGrid = "coaching-module--coaching-course-tags-grid--0Dfjz";
export var coachingCourseTipHeader = "coaching-module--coaching-course-tip-header--kC5Lt";
export var coachingCourseTipText = "coaching-module--coaching-course-tip-text--O38ms";
export var coachingCourseTipTitle = "coaching-module--coaching-course-tip-title--B1W7J";
export var coachingCourseTitle = "coaching-module--coaching-course-title--J0W1o";
export var coachingCourseWrapper = "coaching-module--coaching-course-wrapper--VJInN";
export var coachingDescriptionImage = "coaching-module--coaching-description-image--LKkbH";
export var coachingDescriptionLower = "coaching-module--coaching-description-lower--qiW-o";
export var coachingDescriptionRow = "coaching-module--coaching-description-row--3udwX";
export var coachingDescriptionText = "coaching-module--coaching-description-text--jfnNJ";
export var coachingDescriptionTitle = "coaching-module--coaching-description-title--klpwq";
export var coachingDescriptionUpper = "coaching-module--coaching-description-upper--LJT+V";
export var coachingDescriptionWrapper = "coaching-module--coaching-description-wrapper--IugWv";
export var coachingLineButton = "coaching-module--coaching-line-button--OEb-U";
export var coachingLineButtonWrapper = "coaching-module--coaching-line-button-wrapper--x+4ek";
export var coachingLineText = "coaching-module--coaching-line-text--WHezR";
export var coachingLineWrapper = "coaching-module--coaching-line-wrapper--l3Glo";
export var coachingPriceBodyIcon = "coaching-module--coaching-price-body-icon---U6i0";
export var coachingPriceBodyIconWrapper = "coaching-module--coaching-price-body-icon-wrapper--rmwRM";
export var coachingPriceBodyRow = "coaching-module--coaching-price-body-row--sNU1f";
export var coachingPriceBodyText = "coaching-module--coaching-price-body-text--bT3L-";
export var coachingPriceBodyTextLarge = "coaching-module--coaching-price-body-text-large--tL+F+";
export var coachingPriceButton = "coaching-module--coaching-price-button--MkqTe";
export var coachingPriceButtonRow = "coaching-module--coaching-price-button-row--TldTH";
export var coachingPriceCard = "coaching-module--coaching-price-card--loZyP";
export var coachingPriceHr = "coaching-module--coaching-price-hr--wYGpI";
export var coachingPriceLabel = "coaching-module--coaching-price-label--jmFGv";
export var coachingPriceLabelText = "coaching-module--coaching-price-label-text--3TBDX";
export var coachingPricePopup = "coaching-module--coaching-price-popup--tJgPv";
export var coachingPricePopupCushion = "coaching-module--coaching-price-popup-cushion--9NSAs";
export var coachingPricePopupText = "coaching-module--coaching-price-popup-text--EGKXM";
export var coachingPricePopupWrapper = "coaching-module--coaching-price-popup-wrapper--7eaOC";
export var coachingPriceText = "coaching-module--coaching-price-text--ArqB4";
export var coachingSupport = "coaching-module--coaching-support--SC4zo";
export var coachingSupportInner = "coaching-module--coaching-support-inner--9DdF+";
export var coachingSupportStaff = "coaching-module--coaching-support-staff--0n7MX";
export var coachingSupportStaffButton = "coaching-module--coaching-support-staff-button--d7pQc";
export var coachingSupportStaffImage = "coaching-module--coaching-support-staff-image--9FgHE";
export var coachingSupportText = "coaching-module--coaching-support-text--oKY-A";
export var coachingSupportTitle = "coaching-module--coaching-support-title--J5QWz";
export var coachingTitleEn = "coaching-module--coaching-title-en--49ufy";
export var coachingTitleJp = "coaching-module--coaching-title-jp--5VuF3";