import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import Voices from '../components/voices'
import * as styles from '../styles/coaching.module.css'

const Coaching = () => {
  return (
    <Layout
      isHome={false}
      title="ライフコーチング"
      description="「好きを仕事に」を実現させるためのリアルな情報＆アドバイス「好きなことを仕事にするにはどうしたら良いか」「そもそも自分が好きなことは何なのか」ということから、海外移住や他拠点生活などライフスタイルについてのご相談を承っております。"
    >
      <div className={styles.coachingContainer}>
        <h1 className={styles.coachingTitleEn}>Life Coaching</h1>
        <h2 className={styles.coachingTitleJp}>ライフコーチング</h2>
        <StaticImage
          className={styles.coachingBorderImage}
          alt="Border line"
          src="../images/curve-moss-top.png"
        />
        <div className={styles.coachingDescriptionWrapper}>
          <div className={styles.coachingDescriptionUpper}>
            <div className={styles.coachingDescriptionRow}>
              <h2 className={styles.coachingDescriptionTitle}>
                「好きを仕事に」を実現させるためのリアルな情報＆アドバイス
              </h2>
              <p className={styles.coachingDescriptionText}>
                ライフコーチングでは、「<strong>好きなことを仕事にするにはどうしたら良いか</strong>」「<strong>そもそも自分が好きなことは何なのか</strong>」ということから、<strong>海外移住や他拠点生活などライフスタイルについて</strong>のご相談を承っております。
              </p>
            </div>
            <StaticImage
              className={styles.coachingDescriptionImage}
              alt="ライフコーチング"
              src="../images/blobs-life-coaching.png"
            />
          </div>
          <div className={styles.coachingDescriptionLower}>
            <div className={styles.coachingDescriptionRow}>
              <p className={styles.coachingDescriptionText}>
                現在インターネットにはありとあらゆる情報が溢れていますが、<strong>虚偽の内容や極端に偏った情報</strong>も少なくありません。<br/>
                NEUTRALでは、<strong>私たちの実体験・経験を元にした偏りのないリアルな情報</strong>から、<strong>収入面や生活費など「お金」</strong>のことについても詳しくお答えいたします。
              </p>
            </div>
          </div>
          <div className={styles.coachingLineButtonWrapper}>
            <a className={styles.coachingLineButton} href="https://lin.ee/IBmGSyR" target="_blank" rel="noopener noreferrer">
              無料でLINE相談してみる
            </a>
          </div>
        </div>
        <StaticImage
          className={styles.coachingBorderImage}
          alt="Border line"
          src="../images/curve-moss-bottom.png"
        />
        <div className={styles.coachingCourseWrapper}>
          <div className={styles.coachingCourseCard}>
            <h2 className={styles.coachingCourseTitle}>
              ライフコーチング
            </h2>
            <div className={styles.coachingCourseTagsGrid}>
              <div className={styles.coachingCourseTag}>
                <StaticImage
                  className={styles.coachingCourseTagIcon}
                  alt="「好きなこと」の発見"
                  src="../images/icon-heart.png"
                />
                <p className={styles.coachingCourseTagText}>好きなことの発見</p>
              </div>
              <div className={styles.coachingCourseTag}>
                <StaticImage
                  className={styles.coachingCourseTagIcon}
                  alt="海外移住相談"
                  src="../images/icon-plane.png"
                />
                <p className={styles.coachingCourseTagText}>海外移住相談</p>
              </div>
              <div className={styles.coachingCourseTag}>
                <StaticImage
                  className={styles.coachingCourseTagIcon}
                  alt="フリーランスへの転身"
                  src="../images/icon-laptop.png"
                />
                <p className={styles.coachingCourseTagText}>フリーランスへの転身</p>
              </div>
              <div className={styles.coachingCourseTag}>
                <StaticImage
                  className={styles.coachingCourseTagIcon}
                  alt="ライフスタイルの提案"
                  src="../images/icon-home.png"
                />
                <p className={styles.coachingCourseTagText}>ライフスタイルの提案</p>
              </div>
            </div>
            <div className={styles.coachingCourseTipHeader}>
              <StaticImage
                className={styles.coachingCourseTagIcon}
                alt="「好きを仕事に」「好きなことの発見」"
                src="../images/icon-heart.png"
              />
              <p className={styles.coachingCourseTipTitle}>
                「好きを仕事に」「好きなことの発見」
              </p>
            </div>
            <p className={styles.coachingCourseTipText}>
              自分の「好きなこと」は何か、「好きなこと」を仕事にするためにはどうしたら良いか、といった悩みを解決へ導きます。
            </p>
            <div className={styles.coachingCourseTipHeader}>
              <StaticImage
                className={styles.coachingCourseTagIcon}
                alt="海外移住相談"
                src="../images/icon-plane.png"
              />
              <p className={styles.coachingCourseTipTitle}>
                海外移住相談
              </p>
            </div>
            <p className={styles.coachingCourseTipText}>
              私たちはオランダに約3年間、現在はジョージアに住んでおり、その経験を元に、ビザ取得方法や、現地での家探しや生活、起業方法、税金や生活費のことなどについてお答えします。
            </p>
            <div className={styles.coachingCourseTipHeader}>
              <StaticImage
                className={styles.coachingCourseTagIcon}
                alt="フリーランスへの転身"
                src="../images/icon-laptop.png"
              />
              <p className={styles.coachingCourseTipTitle}>
                フリーランスへの転身
              </p>
            </div>
            <p className={styles.coachingCourseTipText}>
              将来フリーランス(個人事業主)として起業したい方に向け、起業方法、案件の獲得方法、収入面、税金、生活などについてお答えします。
            </p>
            <div className={styles.coachingCourseTipHeader}>
              <StaticImage
                className={styles.coachingCourseTagIcon}
                alt="ライフスタイルの提案"
                src="../images/icon-home.png"
              />
              <p className={styles.coachingCourseTipTitle}>
                ライフスタイルの提案
              </p>
            </div>
            <p className={styles.coachingCourseTipText}>
              「好きなこと」を実現するための道筋を明確にし、無理なく、着実に前進し続けられるライフスタイルをご提案します。
            </p>
            <div className={styles.coachingPriceCard}>
              <div className={styles.coachingPricePopupWrapper}>
                <div className={styles.coachingPricePopup}>
                  <StaticImage
                    className={styles.coachingPricePopupCushion}
                    alt="1回からOK！"
                    src="../images/cushion-fukidashi.png"
                  />
                  <p className={styles.coachingPricePopupText}><strong>1時間から<br/>OK！</strong></p>
                </div>
              </div>
              <div className={styles.coachingPriceLabel}>
                <span className={styles.coachingPriceLabelText}>料金プラン</span>
              </div>
              <p className={styles.coachingPriceText}>
                1時間 <span className={styles.coachingPriceBodyTextLarge}>$50</span>
              </p>
              <hr className={styles.coachingPriceHr}/>
              <div className={styles.coachingPriceBodyRow}>
                <div className={styles.coachingPriceBodyIconWrapper}>
                  <StaticImage
                    className={styles.coachingPriceBodyIcon}
                    alt="√"
                    src="../images/icon-check.png"
                  />
                </div>
                <p className={styles.coachingPriceBodyText}>
                  日本時間の<strong>14時以降いつでもOK</strong>
                </p>
              </div>
              <div className={styles.coachingPriceBodyRow}>
                <div className={styles.coachingPriceBodyIconWrapper}>
                  <StaticImage
                    className={styles.coachingPriceBodyIcon}
                    alt="√"
                    src="../images/icon-check.png"
                  />
                </div>
                <p className={styles.coachingPriceBodyText}>
                  1回につき<strong>1〜3時間</strong>まで
                </p>
              </div>
              <div className={styles.coachingPriceBodyRow}>
                <div className={styles.coachingPriceBodyIconWrapper}>
                  <StaticImage
                    className={styles.coachingPriceBodyIcon}
                    alt="√"
                    src="../images/icon-check.png"
                  />
                </div>
                <p className={styles.coachingPriceBodyText}>
                  <strong>オンラインミーティング</strong>(ZoomまたはLINE)
                </p>
              </div>
              <div className={styles.coachingPriceBodyRow}>
                <div className={styles.coachingPriceBodyIconWrapper}>
                  <StaticImage
                    className={styles.coachingPriceBodyIcon}
                    alt="√"
                    src="../images/icon-check.png"
                  />
                </div>
                <p className={styles.coachingPriceBodyText}>
                  お支払い方法は、<strong>WISE(海外送金)またはPayPal(クレジットカード決済)</strong>
                </p>
              </div>
              <div className={styles.coachingPriceButtonRow}>
                <Link to="/#contact" className={styles.coachingPriceButton}>
                  お問い合わせ
                </Link>
                <Link to="/order" state={{ plan: "coaching" }} className={styles.coachingPriceButton}>
                  お申し込み
                </Link>
              </div>
            </div>
          </div>
        </div>
        <StaticImage
          className={styles.coachingBorderImage}
          style={{margin: "0 0 30px"}}
          alt="Border line"
          src="../images/curve-clear.png"
        />
        <Voices/>
        <StaticImage
          className={styles.coachingBorderImage}
          alt="Border line"
          src="../images/curve-moss-top.png"
        />
        <div className={styles.coachingSupport}>
          <div className={styles.coachingSupportInner}>
            <h2 className={styles.coachingSupportTitle}>私たちがお答えします！</h2>
            <p className={styles.coachingSupportText}>
              「NEUTRAL」はミッションは、<strong>「好きを仕事に」</strong>を応援し、成長させることです。
            </p>
            <p className={styles.coachingSupportText}>
              <strong>インターネットやSNSの普及</strong>により、ひと昔前では無謀だと言われるようなことでも、<strong>実現できる可能性が高まってきました</strong>。
            </p>
            <p className={styles.coachingSupportText}>
              例えば私たちの場合、「<strong>カフェや雑貨屋をやってみたい</strong>」「<strong>フリーランスとして独立したい</strong>」「<strong>海外に住んでみたい</strong>」といった「夢」を実現させてきました。
            </p>
            <p className={styles.coachingSupportText}>
              このような経験を活かして、<strong>「好きなこと」を見つけ、成長させるお手伝い</strong>ができればと思っています。
            </p>
            <div className={styles.coachingSupportStaff}>
              <StaticImage
                className={styles.coachingSupportStaffImage}
                alt="Ami"
                src="../images/staff-ami.png"
              />
              <StaticImage
                className={styles.coachingSupportStaffImage}
                alt="Ami"
                src="../images/staff-shoei.png"
              />
            </div>
          </div>
          <Link to="/#staff" className={styles.coachingSupportStaffButton}>
            プロフィールを見る
          </Link>
        </div>
        <StaticImage
          className={styles.coachingBorderImage}
          alt="Border line"
          src="../images/curve-moss-bottom.png"
        />
        <div className={styles.coachingLineWrapper}>
          <p className={styles.coachingLineText}>まずは気軽に</p>
          <a className={styles.coachingLineButton} href="https://lin.ee/IBmGSyR" target="_blank" rel="noopener noreferrer">
            無料でLINE相談してみる
          </a>
        </div>
      </div>
    </Layout>
  )
}

export default Coaching
